import * as React from "react"
import "@fontsource/raleway/400.css"
import styled from 'styled-components'

// styles

const Headline = styled.h3`
  border-bottom: 1px solid #dfcea1;
  padding-bottom: 9px;
  line-height: normal;
  max-width: 900px;
  margin: 20px auto;

  span {
    border-bottom: 5px solid #e2c3a3;
    padding-bottom: 10px;
    color: #a34115;
    opacity: .5;
    font-size: 20px;
    font-weight: 400;
  }
`;

// markup
const Header = (props) => {
  return (
    <Headline><span>{props.title}</span></Headline>
  )
}

export default Header