import styled from 'styled-components'

export const SynopsisAct = styled.h3`
  margin-top: 40px;
  margin-bottom: 20px;
  font-weight: 400;
`;

export const SynopsisScene = styled.h4`
  font-weight: 500;
  margin-top: 10px;
  margin-bottom: 20px;
`;