import * as React from "react"
import "@fontsource/source-sans-pro"
import styled from 'styled-components'

// styles

const TopLevelGrid = styled.div`

  display: flex;
  flex-wrap: wrap;
  padding: 0;
  max-width: 500px;

  > div {
    box-sizing: border-box;
    flex-grow: 1;
    overflow: hidden; // Or flex might break
    list-style: none;
    > h1, > h2, > h3, > h4, > h5, > h6 { margin: 0; }
    width: 65%;
    margin-bottom: 15px;
    font-family: 'Source Sans Pro';
    
    &:nth-of-type(2n-1) {
      color: #441600;
      width: 35%;
    }

  }
`;

// markup
const InBrief = (props) => {
  return (
    <TopLevelGrid>
      <div>Name</div>
      <div>{props.content.name}</div>

      {props.content.translatedname &&
        <>
        <div>Translated Name</div>
        <div>{props.content.translatedname}</div>
        </>
      }

      <div>Composer</div>
      <div>{props.content.composer}</div>

      <div>Librettist</div>
      <div>{props.content.librettist}</div>

      <div>Language</div>
      <div>{props.content.language}</div>

      <div>Date of premiere</div>
      <div>{props.content.date}</div>

      <div>Number of Acts</div>
      <div>{props.content.acts}</div>

      <div>Music length</div>
      <div>{props.content.musiclength}</div>

    </TopLevelGrid>
  )
}

export default InBrief