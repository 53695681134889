import * as React from "react"
import "@fontsource/raleway/300.css"
import styled from 'styled-components'

// styles

const HeroBox = styled.div`

  background-color: #441600;

  .container {
    max-width: 900px;
    margin: 0 auto 50px;
  }

  h1 {
    font-size: 44px;
    color: #fff;
    padding-top: 50px;
    font-weight: 300;
  };

  h2 {
    font-weight: 300;
    font-size: 21px;
    margin-top: 5px;
    color: #f4e8ca;
    padding-bottom: 50px;
  }
`;

// markup
const Hero = (props) => {
  return (
    <HeroBox>
      <div className="container">
        <h1>{props.title}</h1>
        <h2>{props.subtitle}</h2>
      </div>
    </HeroBox>
  )
}

export default Hero