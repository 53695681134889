import * as React from "react"
import "@fontsource/source-sans-pro"
import styled from 'styled-components'

// styles

const BiblioStyling = styled.ul`
  list-style: none;
  padding-left: 0;
`;

const Book = styled.li`
  margin-bottom: 10px;
`;

// markup
const Bibiliography = (props) => {
  return (
    <BiblioStyling>
      {props.content.map(d => (
        <Book key={d.name}>
          <a href={d.url}>{d.name}</a>, {d.writer}
        </Book>
      ))}

    </BiblioStyling>
  )
}

export default Bibiliography