import styled from 'styled-components'

export const MainBodySection = styled.article`
  max-width: 900px;
  margin: 0 auto 20px;

  @media only screen and (max-width: 900px) {
    padding: 0 20px;
  }
`;

export const HighlightText = styled.h4`
  color: #b13a04;
  line-height: 24px;
`;