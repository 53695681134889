import styled from 'styled-components'

export const StandardFigure = styled.figure`
  display: inline-block;
  margin: 20px 20px 20px 0;
  max-width: ${props => props.maxwidth || ""};
  margin-top: ${props => props.margintop || "20px"};

  figcaption {
    font-style: italic;
    font-size: 14px;
    border-bottom: 1px dotted #dfcea1;
    padding: 5px 0 2px;
  } 
`;

export const ImageRight = styled(StandardFigure)`
  float: right;
  margin-left: 20px;

  figcaption {
    text-align: right;
  } 
`;

export const ImageLeft = styled(StandardFigure)`
  float: left;
  margin-right: 20px;

  figcaption {
    text-align: left;
  } 
`;


export const ImageFull = styled(StandardFigure)`
  figcaption {
    text-align: left;
  } 
`

export const ImageCenter = styled(StandardFigure)`
  text-align: center;
  width: 100%;

  figcaption {
    text-align: center;
  }
`