import * as React from "react"
import "@fontsource/source-sans-pro"
import styled from 'styled-components'

// styles

const TopLevelGrid = styled.div`

  display: flex;
  flex-wrap: wrap;
  margin: 0 0 3em 0;
  padding: 0;

  > div {
    box-sizing: border-box;
    flex-grow: 1;
    padding: 0.8em 1.2em;
    overflow: hidden;
    list-style: none;
    > h1, > h2, > h3, > h4, > h5, > h6 { margin: 0; }
    border-bottom: 1px solid #a34115;
    width: 20%;
    font-family: 'Source Sans Pro';
    &:nth-of-type(3n) {
      width: 60%;
    }

    h3 {
      color: #441600;
      font-size: 18px;
    }
  }
`;

// markup
const CharacterGrid = (props) => {
  return (
    <TopLevelGrid>
      <div><h3>Name</h3></div>
      <div><h3>Vocal Type</h3></div>
      <div><h3>Description</h3></div>

      {props.rows.map(d => (
        <>
          <div>{d.name}</div>
          <div>{d.voice}</div>
          <div>{d.desc}</div>
        </>
      ))}

    </TopLevelGrid>
  )
}

export default CharacterGrid