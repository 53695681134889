import React, { useEffect } from 'react'

const AdBanner = ({
  className,
  style,
  layout,
  format = 'auto',
  client = 'ca-pub-1555873160890186',
  slot = '3461570696',
  responsive = 'true',
  layoutKey,
}) => {
  useEffect(() => {
    try {
      const adsbygoogle = window.adsbygoogle || []
      adsbygoogle.push({})
    } catch (e) {
      console.error(e)
    }
  }, [])
  return (
    <div>
      <ins
        className="adsbygoogle"
        style={style}
        data-ad-layout={layout}
        data-ad-format={format}
        data-ad-client={client}
        data-ad-slot={slot}
        data-ad-layout-key={layoutKey}
        data-full-width-responsive={responsive}
      />
    </div>
  )
}

export default AdBanner